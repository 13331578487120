import React, { Component } from 'react';

import {
    Row,
    FormGroup, Input, Label,CustomInput
} from "reactstrap";

import InputCurrency from "../../components/Vietluck/InputCurrency";
import ButtonLoading from "../../components/FworkTag/ButtonLoading";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";

import apiMyAccount from "../../api/MyAccount";

import utils from "../../utils";
import { faThermometerQuarter } from '@fortawesome/free-solid-svg-icons';

class WithdrawalStore extends React.Component {

    constructor(props) {
        super(props);
        this.listStore = [
            {
                "value": 1,
                "name": "74 Quán Sứ"
            }
        ];
        this.state = {
            type: 11,
            amount: 0,
            store: 1,

            isStoreModalVisible: false,
            isLoading: false
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleChangeMoney(amount) {
        this.setState({
            amount: amount
        })
    }

    handleSubmit() {
        if (this.state.isLoading)
            return;

        if (this.state.amount <= 0 || this.state.amount % 1000 !== 0) {
            utils.alert("Số tiền phải là bội của 1000");
            return;
        }

        this.setState({
            isLoading: true
        });

        apiMyAccount.requestTransfer(utils.copyState(this.state), (err, result) => {
            if (err) {
                this.setState({ isLoading: false });
            } else {
                this.setState({ isLoading: false });
                utils.confirm("Yêu cầu của quý khách đã được ghi nhận. \r\nVui lòng xuất trình giấy tờ với nhân viên để rút tiền", (check) => {
                    this.props.history.goBack()
                })
            }
        })
    }

    render() {
        return (
            <div className="bootstrap-reset p-2">
                <FormGroup>
                    <Label>Tên cửa hàng</Label>
                    <Input
                        type={"select"}
                        name="store"
                        value={this.state.store}
                        onChange={this.handleChange.bind(this)}
                    >
                        {
                            this.listStore.map((store, index) => (
                                <option key={index} value={store.value}>
                                    {store.name}
                                </option>
                            ))
                        }
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label>
                        Số tiền
                    </Label>
                    <InputCurrency
                        onChange={this.handleChangeMoney.bind(this)}
                    />
                </FormGroup>
                <Row className="mt-4">
                    <ButtonLoading
                        title={"Rút tiền"}
                        onClick={this.handleSubmit.bind(this)}
                        className="w-100"
                        loading={this.state.isLoading}
                    />
                </Row>
            </div>
        )
    }
}

class WithdrawalBank extends React.Component {

    constructor(props) {
        super(props);
        this.listBank = this.props.listBank ? this.props.listBank : [
            { value: "VIETCOMBANK", name: "Vietcombank" },
            { value: "VIETINBANK", name: "Vietinbank" },
            { value: "BIDV", name: "BIDV" },
            { value: "AGRIBANK", name: "Agribank" },
            { value: "SACOMBANK", name: "SacomBank" },
            { value: "TECHCOMBANK", name: "TechcomBank" },
            { value: "ACB", name: "Ngân hàng ACB" },
            { value: "VPBANK", name: "VPBank" },
            { value: "DONGABANK", name: "DongABank" },
            { value: "EXIMBANK", name: "Ngân hàng EximBank" },
            { value: "TPBANK", name: "TPBank" },
            { value: "NCB", name: "Ngân hàng Quốc dân (NCB)" },
            { value: "OJB", name: "OceanBank" },
            { value: "MSBANK", name: "Ngân hàng Hàng Hải (MSBANK)" },
            { value: "HDBANK", name: "HDBank" },
            { value: "NAMABANK", name: "NamABank" },
            { value: "OCB", name: "Ngân hàng Phương Đông (OCB)" },
            { value: "SCB", name: "Ngân hàng TMCP Sài Gòn (SCB)" },
            { value: "IVB", name: "Ngân hàng TNHH Indovina (IVB)" },
            { value: "ABBANK", name: "ABBANK" },
            { value: "SHB", name: "SHB" },
            { value: "VIB", name: "VIB" },
            { value: "VNMART", name: "Ví điện tử VnMart" },
        ];

        this.state = {
            type: 12,
            amount: 0,
            bankCode: this.props.bankCode ? this.props.bankCode : "VIETCOMBANK",
            bankAccount: "",
            ownerName: "",

            isLoading: false,
            isWithdrawBalance: this.props.user.isWithdrawBalance,
            isWithdrawFromWining:true
        }
    }
    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    
    handleChangeMoney(amount) {
        this.setState({ amount: amount })
    }

    handleSubmit() {
        if (this.state.isLoading)
            return;

        if (utils.isEmpty(this.state.bankAccount) || utils.isEmpty(this.state.ownerName)) {
            utils.alert("Vui lòng điền đầy đủ thông tin");
            return;
        }

        if (this.state.amount <= 0 || this.state.amount % 1000 !== 0) {
            utils.alert("Số tiền phải là bội của 1000");
            return;
        }

        this.setState({
            isLoading: true
        });

        if(this.state.isWithdrawFromWining){
            apiMyAccount.requestTransferWining(utils.copyState(this.state), (err, result) => {
                if (err) {
                    this.setState({ isLoading: false });
                } else {
                    this.setState({ isLoading: false });
                    utils.confirm("Yêu cầu của quý khách đã được ghi nhận. \r\nThời gian xử lý tối đa là 4h làm việc!", (check) => {
                        // window.location.replace("/withdrawal");
                        this.props.history.goBack()
                    })
                }
            })
        }else{
            apiMyAccount.requestTransfer(utils.copyState(this.state), (err, result) => {
                if (err) {
                    this.setState({ isLoading: false });
                } else {
                    this.setState({ isLoading: false });
                    utils.confirm("Yêu cầu của quý khách đã được ghi nhận. \r\nThời gian xử lý tối đa là 4h làm việc!", (check) => {
                        // window.location.replace("/withdrawal");
                        this.props.history.goBack()
                    })
                }
            })
        }

       
    }
    changeTarget=(val)=>{
        this.setState({ isWithdrawFromWining:val });
    }

    render() {
        return (
            <div className="bootstrap-reset p-2">
                <FormGroup>
                    <Label>Tên ngân hàng</Label>
                    <Input type="select" name="bankCode" value={this.state.bankCode} onChange={this.handleChange.bind(this)}>
                        {
                            this.listBank.map(({ name, value }, index) => (
                                <option key={index} value={value}>
                                    {name}
                                </option>
                            ))
                        }
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label>Số tài khoản</Label>
                    <Input type='numeric' name={"bankAccount"} value={this.state.bankAccount} onChange={this.handleChange.bind(this)} />
                </FormGroup>
                <FormGroup>
                    <Label>Chủ tài khoản</Label>
                    <Input type='text' name={"ownerName"} value={this.state.ownerName} onChange={this.handleChange.bind(this)} />
                </FormGroup>
                <FormGroup>
                    <Label>Số tiền</Label>
                    <InputCurrency onChange={this.handleChangeMoney.bind(this)} />
                </FormGroup>
                {
                    this.state.isWithdrawBalance?null:
                    <FormGroup>
                        <CustomInput                        
                        type={"radio"}
                        id={'1'}
                        checked={!this.state.isWithdrawFromWining}
                        label={"Rút ví tài khoản"}
                        className="my-1"
                        name="wallet"      
                        onChange={()=>{this.changeTarget(false)}}      
                        />
                        <CustomInput                        
                        type={"radio"}
                        id={'2'}
                        checked={this.state.isWithdrawFromWining}
                        label={"Rút ví trúng thưởng"}
                        className="my-1"
                        name="wallet"  
                        onChange={()=>{this.changeTarget(true) }}                        
                        />
                    </FormGroup>
                }
                <Row className="mt-4">
                    <ButtonLoading
                        onClick={this.handleSubmit.bind(this)}
                        className="w-100"
                        title="Rút tiền"
                        loading={this.state.isLoading}
                    />
                </Row>

            </div>
        )
    }
}

export class WithdrawalMomo extends React.Component {
    componentDidMount() {
        utils.confirm("Tính năng đang phát triển!", (check) => {
            this.props.history.goBack()
        })
    }

    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }
}

export class WithdrawalViettel extends React.Component {
    componentDidMount() {
        utils.confirm("Tính năng đang phát triển!", (check) => {
            this.props.history.goBack()
        })
    }

    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }
}

export class WithdrawalZalo extends React.Component {
    componentDidMount() {
        utils.confirm("Tính năng đang phát triển!", (check) => {
            this.props.history.goBack()
        })
    }

    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }
}

export class WithdrawalCard extends React.Component {
    componentDidMount() {
        utils.confirm("Tính năng đang phát triển!", (check) => {
            this.props.history.goBack()
        })
    }

    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }
}

export class WithdrawalItems extends Component {
    constructor(props) {
        super(props);
        this.id = function () {
            switch (window.location.hash) {
                case "#2":
                    return 2;
                case "#3":
                    return 3;
                case "#4":
                    return 4;
                case "#5":
                    return 5;
                case "#6":
                    return 6;
                case "#17":
                    return 17;
                default:
                    return 1;
            }
        }
    }

    render() {
        switch (this.id()) {
            case 1:
                return <WithdrawalStore history={this.props.history} />;
            case 2:
                return <WithdrawalBank history={this.props.history} user={this.props.user}/>;
            case 3:
                // return <WithdrawalMomo history={this.props.history}/>;
                return <WithdrawalBank listBank={[{ value: "VI_MOMO", name: "Ví điện tử Momo" }]} bankCode={"VI_MOMO"} history={this.props.history} />;
            case 4:
                // return <WithdrawalViettel history={this.props.history}/>;
                return <WithdrawalBank listBank={[{ value: "VI_VIETTEL_PAY", name: "Ví điện tử ViettelPay" }]} bankCode={"VI_VIETTEL_PAY"} history={this.props.history} />;
            case 5:
                // return <WithdrawalZalo history={this.props.history}/>;
                return <WithdrawalBank listBank={[{ value: "VI_ZALO_PAY", name: "Ví điện tử ZaloPay" }]} bankCode={"VI_ZALO_PAY"} history={this.props.history} />;
            case 6:
                return <WithdrawalCard history={this.props.history} />;
            case 17:
                return <WithdrawToWallet history={this.props.history} user={this.props.user} />;
            default:
                break;
        }
    }
}

export class WithdrawToWallet extends Component{  
    constructor(props){
        super(props);
        this.state={
            amount:0,
            isLoading:false
        }
    }
    
    handleChangeMoney(amount) {
        this.setState({
            amount: amount
        })
    }
    handleSubmit=()=>{
        if(this.state.isLoading)
        return;

        if(this.state.amount <= 0 || this.state.amount%1000 !== 0) {
            utils.alert("Số tiền phải là bội của 1000");
            return;
        }

        this.setState({
            isLoading: true
        });
        apiMyAccount.requestWithdrawToWallet({amount:this.state.amount},(err,res)=>{
            if(err) {
                this.setState({isLoading: false});
                utils.alert(err)
            } else {
                this.setState({isLoading: false});
               
                utils.confirm("Yêu cầu của quý khách đã được thực hiện.", (check) => {
                   window.location.reload();
                })
            }
        })
    }
    render(){
        return (
            <div className="bootstrap-reset p-2">
                <FormGroup>
                    <Label>
                        Tiền thưởng
                    </Label>
                    <Input
                        type={"text"}
                        name="prize"
                        value={( this.props.user.winingBalance*1000) .getMoneyFormat()}
                        disabled = {'disabled'}
                    >                        
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label>
                        Số tiền
                    </Label>
                    <InputCurrency
                        onChange={this.handleChangeMoney.bind(this)}
                    />
                </FormGroup>
                <Row className="mt-4">
                    <ButtonLoading
                        title={"Rút tiền"}
                        onClick={this.handleSubmit.bind(this)}
                        className="w-100"
                        loading={this.state.isLoading}
                    />
                </Row>
            </div>
        )
    }
}

export default withRouter( connect(store => ({    
    user: store.user
}), {})(WithdrawalItems));
