import React, { Component } from 'react';
import utils from "../../utils";
import apiMyAccount from "../../api/MyAccount";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import InputCurrency from "../../components/Vietluck/InputCurrency";
import ButtonLoading from "../../components/FworkTag/ButtonLoading";

import PushNotify from "../../components/Vietluck/PushNotify";

import { connect } from "react-redux";
import LoadingPage from "../../components/LoadingPage";

const logo = {
    vnpay: "/img/logo/vnpayqr.png",
    vietcombank: "/img/logo/vietcombank.png",
    sacombank: "/img/logo/sacombank.png",
    viettel: require('../../assets/images/icon/viettelpay.png'),
    zalo: require('../../assets/images/icon/zalopay.png'),
    momo: require('../../assets/images/icon/momo.png')
};

class RechargeVNPAY extends React.Component {

    constructor(props) {
        super(props);
        this.listMoneySuggest = [
            {
                value: 50,
                name: "50.000"
            },
            {
                value: 100,
                name: "100.000"
            },
            {
                value: 200,
                name: "200.000"
            },
            {
                value: 500,
                name: "500.000"
            }
        ];
        this.listBank = [
            { value: "VIETCOMBANK", name: "Vietcombank" },
            { value: "VIETINBANK", name: "Vietinbank" },
            { value: "BIDV", name: "BIDV" },
            { value: "AGRIBANK", name: "Agribank" },
            { value: "SACOMBANK", name: "SacomBank" },
            { value: "TECHCOMBANK", name: "TechcomBank" },
            { value: "ACB", name: "Ngân hàng ACB" },
            { value: "VPBANK", name: "VPBank" },
            { value: "DONGABANK", name: "DongABank" },
            { value: "EXIMBANK", name: "Ngân hàng EximBank" },
            { value: "TPBANK", name: "TPBank" },
            { value: "NCB", name: "Ngân hàng Quốc dân (NCB)" },
            { value: "OJB", name: "OceanBank" },
            { value: "MSBANK", name: "Ngân hàng Hàng Hải (MSBANK)" },
            { value: "HDBANK", name: "HDBank" },
            { value: "NAMABANK", name: "NamABank" },
            { value: "OCB", name: "Ngân hàng Phương Đông (OCB)" },
            { value: "SCB", name: "Ngân hàng TMCP Sài Gòn (SCB)" },
            { value: "IVB", name: "Ngân hàng TNHH Indovina (IVB)" },
            { value: "ABBANK", name: "ABBANK" },
            { value: "SHB", name: "SHB" },
            { value: "VIB", name: "VIB" },
            { value: "VNMART", name: "Ví điện tử VnMart" },
        ];
        this.listPaymentMethod = [{ value: 1, name: "Thẻ ATM và tài khoản ngân hàng" }];

        this.state = {
            amount: 0,
            bankCode: "VIETCOMBANK",
            paymentMethod: 1,

            isLoading: false

        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleChangeMoney(amount) {
        this.setState({ amount: amount })
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        });
    }

    handleSubmit() {
        if (utils.isEmpty(this.state.amount) || this.state.amount === 0) {
            utils.alert("Nhập số tiền");
            return;
        }

        if (this.state.amount % 1000 !== 0) {
            utils.alert("Số tiền phải là bội của 1000");
            return;
        }

        this.setState({ isLoading: true });
        apiMyAccount.VNPAY_Payment({
            vnp_Amount: this.state.amount,
            vnp_BankCode: this.state.bankCode
        }, (err, result) => {
            if (err) {
                this.setState({ isLoading: false });
            } else {
                this.setState({ isLoading: false });

                //window.open(result.data, "_blank")
                window.location.replace(result.data)
            }
        });
    }

    render() {
        return (
            <div className="bootstrap-reset p-2">
                <Row className="justify-content-center">
                    <img src={logo["vnpay"]} alt="vnpay" style={{ height: "40px", width: "200px" }} />
                </Row>
                <FormGroup className="mt-3">
                    <Label>Loại thanh toán</Label>
                    <Input type="select" onChange={this.handleChange.bind(this)} name="paymentMethod">
                        {
                            this.listPaymentMethod.map(({ name, value }, index) => (
                                <option key={index} value={value}>
                                    {name}
                                </option>
                            ))
                        }
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label>Tên ngân hàng</Label>
                    <Input type="select" onChange={this.handleChange.bind(this)} name="bankCode">
                        {
                            this.listBank.map(({ name, value }, index) => (
                                <option key={index} value={value}>
                                    {name}
                                </option>
                            ))
                        }
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label>Số tiền</Label>
                    <InputCurrency onChange={this.handleChangeMoney.bind(this)} name="amount" />
                </FormGroup>
                {/*<Row>*/}
                {/*    {*/}
                {/*        this.listMoneySuggest.map(({name, value}, index) => (*/}
                {/*            <Button outline className="w-25">{name}</Button>*/}
                {/*        ))*/}
                {/*    }*/}
                {/*</Row>*/}
                <ButtonLoading
                    title="Tiếp tục"
                    loading={this.state.isLoading}
                    onClick={this.handleSubmit.bind(this)}
                    className={"w-100"}
                />
            </div>
        )
    }
}

class RechargeMomo extends React.Component {

    constructor() {
        super();
        this.listBank = [
          {
            chu_tai_khoan: "Trịnh Đình Khánh",
            so_tai_khoan: "0969668800",
          },
          {
            chu_tai_khoan: "Trịnh Đình Khánh",
            so_tai_khoan: "0964005005",
          },
          {
            chu_tai_khoan: "Trịnh Đình Khánh",
            so_tai_khoan: "0784259259",
          },
        ];
      }
    
      componentDidMount() {
        
      }
    
      handleCopyClipboard(id) {
        let copyText = document.querySelector("#copy-bank-account-"+id);
        copyText.select();
        document.execCommand("copy");
        PushNotify(1, "success", "Sao chép số tài khoản", "Đã sao chép");
      }
    
      render() {
        return (
          <div className="bootstrap-reset p-2">
            <div className="bank-box">
              <div className="font-weight-bold">
                VietLuck hỗ nạp tiền qua Momo với nội dung sau:
              </div>
              <Row className="font-weight-bold text-danger justify-content-center">
                NAP[SĐT]
              </Row>
              <Row className="font-weight-bold justify-content-center">
                Ví dụ: NAP{this.props.user.phoneNumber}
              </Row>
            </div>
            <br/>
            {this.listBank.map((bank, index) => (
              <>
              <div key={index} className="bank-box">
                <Row className="justify-content-center">
                  <img src={logo[bank.bank]} alt={bank.bank} className="w-50" />
                </Row>
                
                <Row className="mt-2">
                  <Col>
                    <Row>
                      <span className="font-weight-bold">Chủ tài khoản:&nbsp;</span>
                      {bank.chu_tai_khoan}
                    </Row>
                    <Row>
                      <span className="font-weight-bold">STK:&nbsp;{bank.so_tai_khoan}</span>
                      
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-1 mb-2">
                  {/*<span className="btn-copy shadow">Sao chép</span>*/}
                  {/*<Button onClick={this.handleCopyClipboard.bind(this)}>Sao chép số TK</Button>*/}                            
                  <Col className="text-center px-2">
                    <Button
                      className="w-100"
                      onClick={this.handleCopyClipboard.bind(this,index)}
                    >
                      Sao chép số TK
                    </Button>
                  </Col>
                  <Col className="text-center px-2">
                    <a href={"momo://"}>
                      <Button className="w-100">Mở ví momo</Button>
                    </a>
                  </Col>
                </Row>
               
              </div>
              <br/>
              </>
            ))}
    
            
            <div className="mt-2">
              (*) Yêu cầu của quý khách sẽ được xử lý trong tối đa 5 phút
            </div>
            <input
              style={{
                opacity: 0,
              }}
              readOnly
              id="copy-bank-account-0"
              value={this.listBank[0].so_tai_khoan}
            />
            <input
              style={{
                opacity: 0,
              }}
              readOnly
              id="copy-bank-account-1"
              value={this.listBank[1].so_tai_khoan}
            />
          </div>
        );
      }
}

class RechargeViettel extends React.Component {

    constructor() {
        super();
        this.listBank = [
            {
                chu_tai_khoan: 'Trịnh Đình Khánh',
                so_tai_khoan: '0784259259',
            }
        ]
    }

    componentDidMount() {
        utils.alert(
            `VietLuck hỗ trợ hình thức nạp tiền chuyển khoản qua ViettelPay với nội dung chuyển khoản sau: NAP[SĐT]\r\nVí dụ: NAP${this.props.user.phoneNumber}\r\nYêu cầu chuyển tiền sẽ được xử lý tối đa trong 15 phút!`
        )
    }

    handleCopyClipboard() {
        let copyText = document.querySelector("#copy-bank-account");
        copyText.select();
        document.execCommand("copy");
        PushNotify(1, "success", "Sao chép số tài khoản", "Đã sao chép")
    }

    render() {
        return (
            <div className="bootstrap-reset p-2">
                <div className="bank-box">
                    <div className="font-weight-bold">
                        VietLuck hỗ nạp tiền chuyển khoản qua ViettelPay với nội dung sau:
                    </div>
                    <Row className="font-weight-bold text-danger justify-content-center">
                        NAP[SĐT]
                    </Row>
                    <Row className="font-weight-bold justify-content-center">
                        Ví dụ: NAP{this.props.user.phoneNumber}
                    </Row>
                </div>
                <div className="font-weight-bold mt-4 mb-1">
                    Danh sách tài khoản Viettel
                </div>
                {
                    this.listBank.map((bank, index) => (
                        <div key={index} className="bank-box">
                            <Row className="justify-content-center">
                                <img src={logo[bank.bank]} alt={bank.bank} className="w-50" />
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Row>
                                        <span className="font-weight-bold">Chủ tài khoản:&nbsp;</span>
                                        {bank.chu_tai_khoan}</Row>
                                    <Row>
                                        <span className="font-weight-bold">STK:&nbsp;</span>
                                        <input readOnly id="copy-bank-account" value={bank.so_tai_khoan} />
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-1 mb-2">
                                {/*<span className="btn-copy shadow">Sao chép</span>*/}
                                {/*<Button onClick={this.handleCopyClipboard.bind(this)}>Sao chép số TK</Button>*/}
                                <Col className="text-center px-2">
                                    <a href={"viettelpay://"}>
                                        <Button className="w-100">Mở ViettelPay</Button>
                                    </a>
                                </Col>
                                <Col className="text-center px-2">
                                    <Button className="w-100" onClick={this.handleCopyClipboard.bind(this)}>Sao chép số TK</Button>
                                </Col>
                            </Row>
                        </div>
                    ))
                }

                {/* <div className="px-4 py-2 text-center">
                    Hoặc quét mã QR
                    <img src="/img/qr/vietluck-qr-viettelpay.jpg" className="w-75" alt="viettelpay" />
                    <div className="mt-1">
                        <a href={"/img/qr/vietluck-qr-viettelpay.jpg"} download>
                            <Button>Lưu mã</Button>
                        </a>
                    </div>
                </div> */}
                <div className="mt-2">
                    (*) Yêu cầu của quý khách sẽ được xử lý trong tối đa 15 phút
                </div>
            </div>
        )
    }
}

class RechargeZalo extends React.Component {

    constructor() {
        super();
        this.listBank = [
            {
                chu_tai_khoan: 'VietLuck',
                so_tai_khoan: '0784259259',
            }
        ]
    }

    componentDidMount() {
        utils.alert(
            `VietLuck hỗ trợ hình thức nạp tiền chuyển khoản qua ZaloPay với nội dung chuyển khoản sau: NAP[SĐT]\r\nVí dụ: NAP${this.props.user.phoneNumber}\r\nYêu cầu chuyển tiền sẽ được xử lý tối đa trong 15 phút!`
        )
    }

    handleCopyClipboard() {
        let copyText = document.querySelector("#copy-bank-account");
        copyText.select();
        document.execCommand("copy");
        PushNotify(1, "success", "Sao chép số tài khoản", "Đã sao chép")
    }

    render() {
        return (
            <div className="bootstrap-reset p-2">
                <div className="bank-box">
                    <div className="font-weight-bold">
                        VietLuck hỗ nạp tiền chuyển khoản qua ZaloPay với nội dung sau:
                    </div>
                    <Row className="font-weight-bold text-danger justify-content-center">
                        NAP[SĐT]
                    </Row>
                    <Row className="font-weight-bold justify-content-center">
                        Ví dụ: NAP{this.props.user.phoneNumber}
                    </Row>
                </div>
                <div className="font-weight-bold mt-4 mb-1">
                    Danh sách tài khoản ZaloPay
                </div>
                {
                    this.listBank.map((bank, index) => (
                        <div key={index} className="bank-box">
                            <Row className="justify-content-center">
                                <img src={logo[bank.bank]} alt={bank.bank} className="w-50" />
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Row>
                                        <span className="font-weight-bold">Chủ tài khoản:&nbsp;</span>
                                        {bank.chu_tai_khoan}</Row>
                                    <Row>
                                        <span className="font-weight-bold">STK:&nbsp;</span>
                                        <input readOnly id="copy-bank-account" value={bank.so_tai_khoan} />
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-1 mb-2">
                                {/*<span className="btn-copy shadow">Sao chép</span>*/}
                                <Col className="text-center px-2">
                                    <a href={"zalopay://"}>
                                        <Button className="w-100">Mở ZaloPay</Button>
                                    </a>
                                </Col>
                                <Col className="text-center px-2">
                                    <Button className="w-100" onClick={this.handleCopyClipboard.bind(this)}>Sao chép số TK</Button>
                                </Col>
                            </Row>
                        </div>
                    ))
                }

                <div className="px-4 py-2 text-center">
                    Hoặc quét mã QR
                    <img src="/img/qr/vietluck-qr-zalopay.jpg" className="w-75" alt="zalopay" />
                    <div className="mt-1">
                        <a href={"/img/qr/vietluck-qr-zalopay.jpg"} download>
                            <Button>Lưu mã</Button>
                        </a>
                    </div>
                </div>
                <div className="mt-2">
                    (*) Yêu cầu của quý khách sẽ được xử lý trong tối đa 15 phút
                </div>
            </div>
        )
    }
}

class RechargeBank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listBank: []
        }
    }

    componentDidMount() {
        fetch("/list-bank.json")
            .then(res => res.json())
            .then(res => {
                this.setState({ listBank: res })
            });
        utils.alert(
            `VietLuck hỗ trợ hình thức nạp tiền qua tài khoản ngân hàng với nội dung chuyển khoản sau: NAP[SĐT]\r\nVí dụ: NAP${this.props.user.phoneNumber}\r\nYêu cầu chuyển tiền sẽ được xử lý tối đa trong 15 phút!`
        )
    }

    handleCopyClipboard(bank) {
        let copyText = document.querySelector("#copy-bank-" + bank);
        copyText.select();
        document.execCommand("copy");
        PushNotify(1, "success", "Sao chép số tài khoản", "Đã sao chép")
    }


    render() {
        return (
            <div className="bootstrap-reset p-2">
                <div className="bank-box">
                    <div className="font-weight-bold">
                        VietLuck hỗ nạp tiền qua hình thức chuyển khoản ngân hàng với nội dung sau:
                    </div>
                    <Row className="font-weight-bold text-danger justify-content-center">
                        NAP[SĐT]
                    </Row>
                    <Row className="font-weight-bold justify-content-center">
                        Ví dụ: NAP{this.props.user.phoneNumber}
                    </Row>
                </div>
                <div className="font-weight-bold mt-4 mb-1">
                    Danh sách tài khoản ngân hàng
                </div>
                {
                    this.state.listBank.map((bank, index) => (
                        <div key={index} className="bank-box my-2 ">
                            <Row className="justify-content-center">
                                <img src={logo[bank.bank]} alt={bank.bank} className="w-50" style={{ height: "50px" }} />
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Row>
                                        <span className="font-weight-bold">Chủ tài khoản:&nbsp;</span>
                                        {bank.chu_tai_khoan}</Row>
                                    <Row>
                                        <span className="font-weight-bold">Tên ngân hàng:&nbsp;</span>
                                        {bank.bank.toUpperCase()}
                                    </Row>
                                    <Row>
                                        <span className="font-weight-bold">STK:&nbsp;</span>
                                        <input readOnly className="copy-bank-account" id={`copy-bank-${bank.bank}`} value={bank.so_tai_khoan} />
                                    </Row>
                                    <Row>
                                        <span className="font-weight-bold">Chi nhánh:&nbsp;</span>
                                        {bank.chi_nhanh}
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="justify-content-end">
                                {/*<span className="btn-copy shadow">Sao chép</span>*/}
                                <Button onClick={this.handleCopyClipboard.bind(this, bank.bank)}>Sao chép số TK</Button>
                            </Row>

                        </div>
                    ))
                }
                <div className="mt-2">
                    (*) Yêu cầu của quý khách sẽ được xử lý trong tối đa 15 phút làm việc
                </div>
            </div>
        )
    }
}

class RechargeItems extends Component {

    constructor(props) {
        super(props);
        this.id = window.location.hash === "#2" ? 2 :
            window.location.hash === "#3" ? 3 :
                window.location.hash === "#4" ? 4 :
                    window.location.hash === "#5" ? 5 : 1;
        this.state = {
            isLoaded: false
        }
    }

    componentDidMount() {
        if (!(this.props.user.authToken && this.props.user.isActive)) {
            utils.confirm("Vui lòng đăng nhập", (check) => {
                window.location.replace("/auth/sign-in")
            })
        } else {
            this.setState({ isLoaded: true })
        }
    }

    render() {
        if (!this.state.isLoaded)
            return <LoadingPage />;
        switch (this.id) {
            case 1:
                return <RechargeVNPAY />;
            case 2:
                return <RechargeBank user={this.props.user} />;
            case 3:
                return <RechargeMomo user={this.props.user} />;
            case 4:
                return <RechargeViettel user={this.props.user} />;
            case 5:
                return <RechargeZalo user={this.props.user} />;
            default:
                break;

        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, {})(RechargeItems);
