import React, {Component} from 'react';

import ListItem from "../../components/Vietluck/ListItem";

import {Link} from "react-router-dom";

const listWithdrawalMethod = [   
    {
        title: "Rút tiền về tài khoản ngân hàng",
        icon: require("../../assets/images/icon/atm.png"),
        type: 2
        // childrenInput: <Withdrawal_Bank onSubmit={this.handleSubmit.bind(this)}/>
    },
    {
        title: "Rút về tài khoản VietLuck",
        icon: require("../../assets/images/logo/logo.png"),
        type: 17
        // childrenInput: <Withdrawal_Momo onSubmit={this.handleSubmit.bind(this)}/>
    }
     
];

class Withdrawal extends Component {
    render() {
        return (
            <div className="p-2">
                <div>
                    Vietluck hỗ trợ người dùng rút tiền qua các hình thức sau:
                </div>
                {
                    listWithdrawalMethod.map((l, i) => (
                        <Link to={`/withdrawal-items#${l.type}`} key={i} className="link-unset">
                            <ListItem
                                avatar={l.icon}
                                title={l.title}
                            />
                        </Link>
                    ))
                }
            </div>
        );
    }
}
 
export default Withdrawal;
