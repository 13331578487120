import rootApi, {getToken} from "./rootApi";

import {filterUserInfo} from "../utils";
import ErrorMessage from "../utils/ErrorMessage";

const path = require("../config").path;

function login(data, callback) {
    rootApi.post(path.auth.login, {
        phoneNumber: data.phoneNumber,
        password: data.password,
    })
        .then(res => {
            return callback(null, filterUserInfo(res.data));
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        })
}

function register(data, callback) {
    rootApi.post(path.auth.register, {
        phoneNumber: data.phoneNumber,
        password: data.password,
        // cityId: data.cityId,
        // districtId: data.districtId,
        // idCardNumber: data.idCardNumber,
        // fullName: "Chưa xác định",
        // gender: "M",
        // email: "example@gmail.com",
        acceptedTerms: true,
        // address: "Chưa xác định",
        captchaValue: "dcm",
        shareCode:data.shareCode
    })
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        })
}

function requestChangePassword(phoneNumber, callback) {
    rootApi.post(path.auth.password_recovery_request, {
        phoneNumber: phoneNumber,
    })
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        })
}

function changePasswordRecovery(data, callback) {
    rootApi.post(path.auth.password_recovery_change, {
        phoneNumber: data.phoneNumber,
        password: data.password,
        otp: data.otp
    })
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        })
}

function requestOTP(callback) {
    rootApi.get(path.auth.resendOTP, {headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`,
        }})
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        })
}

function confirmOTP(OTP, callback) {

    rootApi.post(path.auth.verify, {
        otp: OTP
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`,
        }
    })
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });

}


export default {
    login: login,
    register: register,
    confirmOTP: confirmOTP,
    requestOTP: requestOTP,
    requestChangePassword: requestChangePassword,
    changePasswordRecovery: changePasswordRecovery
};
