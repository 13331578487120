import * as firebase from "firebase/app";
import "firebase/messaging";

// const log = require("loglevel");

firebase.initializeApp({
    // Project Settings => Add Firebase to your web app
    apiKey: "AIzaSyD41OdMx5bafHiJajUAhpt_a5XRBCsxML0",
    authDomain: "vietluck-4492e.firebaseapp.com",
    databaseURL: "https://vietluck-4492e.firebaseio.com",
    projectId: "vietluck-4492e",
    storageBucket: "vietluck-4492e.appspot.com",
    messagingSenderId: "383844660073",
    appId: "1:383844660073:web:c4e0fecb086af50adfc3f8",
    measurementId: "G-FVDDC2XS16"
});

let messaging;

if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
    messaging.usePublicVapidKey("BEDdrVf3xY_iavk_-7qN0OATiePZCHHOGHFctUmsnV-17d4pZSPoUmiw1cGt7kT7_Scthqt54hsqYsNYW7mrNg0");
    messaging.requestPermission()
    // if (fcmToken === "" || fcmToken === null || fcmToken === undefined) {
    messaging.getToken()
        .then(fcmToken => {
            // log.info("new fcmToken: " + fcmToken);
             console.log(fcmToken)
            localStorage.setItem("fcmToken", fcmToken);
        })
    // } else {
    //     log.info("current fcmToken: " + fcmToken); 
    // }
}

export {messaging}
