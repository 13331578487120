// export const root = "http://192.168.1.229:3001";
export const root = "https://api.vietluck.vn";
//export const root = "http://localhost:3001";

const api_path = root + `/api/v1`;

const path = {
  config: {
    config: api_path + "/customer/config",
  },
  application: {
    application: api_path + "/application",
  },
  groupPlay: {
    get:api_path+"/group-buy/",
    buyStock:api_path+"/group-buy/buy",
    history:api_path+"/group-buy/history",
    detail:api_path+"/group-buy/detail",
    detailSummary:api_path+"/group-buy/summary"
  },
  affiliate:{
    get:api_path+"/affiliate",
    deposit:api_path+"/affiliate/deposit",
    check:api_path+"/affiliate/check",
  },
  result: {
    mega645: api_path + "/lottery-results?productid=1",
    power655: api_path + "/lottery-results?productid=2",
    max3d: api_path + "/lottery-results?productid=4",
    max4d: api_path + "/lottery-results?productid=3",
    keno: api_path + "/lottery-results?productid=6",
    235: api_path + "/lottery-results?productid=7",
    234: api_path + "/lottery-results?productid=8",
    "6x36": api_path + "/lottery-results?productid=9",
    thantai: api_path + "/lottery-results?productid=10",
    123: api_path + "/lottery-results?productid=11",
    "max3d_pro": api_path + "/lottery-results?productid=12",
    all: api_path + "/lottery-results/lottery-result-all",
    "ktmn":api_path + "/lottery-results?productid=14",
  },

  analytic: {
    mega645: api_path + "/lottery-results/statistical?productid=1",
    power655: api_path + "/lottery-results/statistical?productid=2",
    max3d: api_path + "/lottery-results/statistical?productid=4",
    max4d: api_path + "/lottery-results/statistical?productid=3",
    keno: api_path + "/lottery-results/statistical?productid=6",
  },

  auth: {
    login: api_path + "/auth",
    register: api_path + "/auth/register",
    password_recovery_request: api_path + "/customer/password_recovery",
    password_recovery_change:
      api_path + "/customer/change_password_from_recovery",
    verify: api_path + "/auth/verify",
    resendOTP: api_path + "/auth/resendOtp",
    firebase: api_path + "/web-notifications/setTokenFirebase",
  },

  customer: {
    get_info: api_path + "/customer/me",
    edit_info: api_path + "/customer",
    change_password: api_path + "/customer/change_password",
    wallet: {
      info: api_path + "/wallet",
      transaction: api_path + "/wallet/transaction-history",
    },
    request_transfer: api_path + "/customer/request_transfer",
    request_transferWining:api_path+"/customer/withdraw_wining",
    withdraw_to_wallet:api_path+"/customer/withdraw-wallet",
    shop_history: api_path + "/order",
    vnpay: {
      request: api_path + "/payments/vnp-url",
      return: api_path + "/payments/vnpay-return",
      check: root + "/vnpaypayment",
    },
    megapay:{
      createOrder:api_path+"/payments/megapay-url",
      returnOrder:api_path+"/payments/megapay-return",
      createRecharge:api_path+"/payments/megapay-recharge"
    },
    gPay:{      
      returnOrder:api_path+"/payments/gpay-return",
      createRecharge:api_path+"/payments/gpay-url",
      createRechargeInternational:api_path+"/payments/gpayi-url",
      createVA:api_path+"/payments/va-create",
      createVABIDV:api_path+"/payments/va-create-bidv",
      createOrder:api_path+"/payments/gpay-order",
    },
    momo: {
      request: api_path + "/payments/momo-url",
      return: api_path + "/payments/momo-return",
      check: root + "/momopayment",
    },
    notify: api_path + "/web-notifications",
    notify_setAsReadAll: api_path + "/web-notifications/set-as-read",
    attachment: api_path + "/attachment/customer",
    request: api_path + "/customer/request",
    ticket: api_path + "/ticket",
  },

  order: {
    createOrder: api_path + "/order",
    checkPrice: api_path + "/order/check-price",
  },

  product: {
    mega645: api_path + "/product/1",
    power655: api_path + "/product/2",
    max4d: api_path + "/product/3",
    max3d: api_path + "/product/4",
    keno: api_path + "/product/6",
    235: api_path + "/product/7",
    234: api_path + "/product/8",
    "6x36": api_path + "/product/9",
    thantai: api_path + "/product/10",
    123: api_path + "/product/11",
    max3d_pro: api_path + "/product/12"
  },

  address: {
    path: api_path + "/administrative",
    city: api_path + "/administrative/city",
  },

};

export default path;
